<template>
<div class="page-view">
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="page-title">Users</span>
    </div>
    <div class="text item" style="display: flex; justify-content: space-between">
     <div class="table-user">
       <div class="add-btn" style="display: flex; justify-content: space-between">
           <el-input placeholder="Search users"  v-model="searchUsers" style="width: 300px"></el-input>
         <el-button type="primary" icon="el-icon-plus" plain @click="addModal"></el-button>
       </div>
       <el-table
           v-loading="loading"
           :data="usersList"
           border
           style="width: 100%"
           ref="tableData"
           highlight-current-row
           @current-change="handleCurrentChangeRow"
           :row-class-name="activeRow"
       >
         <el-table-column
             label="#"
             type="index"
             width="50"
         >
         </el-table-column>
         <el-table-column
             prop="name"
             label="Name"
         >
         </el-table-column>
         <el-table-column
             prop="roleId"
             label="roleId"
         >
           <template slot-scope="scope">{{getRoleName(scope.row.roleId)}}</template>

         </el-table-column>
         <el-table-column
             prop="addedDate"
             label="addedDate"
         >
           <template slot-scope="scope">{{scope.row.addedDate| commonDateFormat}}</template>
         </el-table-column>
         <el-table-column
             label="isDeleted"
         >
           <template slot-scope="scope">
             <span style="margin-left: 10px">{{ scope.row.isDeleted }}</span>
           </template>
         </el-table-column>
         <el-table-column
             label="Action"
             width="70"
         >
             <template slot-scope="scope">
               <el-button type="primary" icon="el-icon-edit" circle @click.stop="update(scope.row)"></el-button>
             </template>

         </el-table-column>
       </el-table>
       <el-row class="pagination_row" v-if="pagination.page">
         <el-pagination
             background
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page="pagination.page"
             :page-size= "pagination.count"
             :page-sizes="[5,10, 20, 30, 50]"
             layout="total, sizes, prev, pager, next, jumper"
             :total = "pagination.totalCount"
         >
         </el-pagination>
       </el-row>
     </div>
      <div class="table-accounts"
      >
      <div style="display: flex">
        <p class="users__name" v-if="userSelect"> {{this.user.name}}</p>
        <p class="users__name" style="width: 200px" v-else>Users Accounts</p>
        <div class="add-btn">
          <el-button type="primary" icon="el-icon-plus" plain  @click="showForm()"></el-button>
        </div>
      </div>
        <el-table
            v-if="this.userAccounts.length > 0"
            v-loading="userAccountloading"
            :data="userAccounts"
            border
            style="width: 100%"
            ref="tableData"
            highlight-current-row
        >
          <el-table-column
              label="#"
              type="index"
              width="50"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="Name"
          >
          </el-table-column>
          <el-table-column
              prop="account"
              label="account"
          >
          </el-table-column>
          <el-table-column
              prop="bankCode"
              label="bankCode"
          >
          </el-table-column>
          <el-table-column
              prop="branch"
              label="branch"
          >
          </el-table-column>

          <el-table-column
              prop="inn"
              label="inn"
          >
          </el-table-column>
          <el-table-column
              label="Action"
              width="70"
          >
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" circle @click.stop="showForm(scope.row)"></el-button>
            </template>

          </el-table-column>

        </el-table>
        <p v-if="isUserAccount && this.userAccounts.length <= 0">нет данных</p>
      </div>
    </div>
  </el-card>
  <el-dialog
      title="Add User"
      :visible.sync="addUser"
      width="30%"
      center>
    <div class="modal-content">
      <el-form ref="addUsersForm" :model="form" :rules="rules">
        <el-form-item label="Name" prop="Name">
          <el-input v-model="form.Name"></el-input>
        </el-form-item>
        <el-form-item label="UserName" prop="UserName">
          <el-input v-model="form.UserName"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="Password">
          <el-input placeholder="Парол"  show-password v-model="form.Password"></el-input>
        </el-form-item>
        <div style="display: flex; width: 100%; justify-content: space-between">

          <el-form-item prop="RoleId" style="width: 47%">
            <div class="select-group">
              <label>Role</label>
              <el-select v-model="form.RoleId" placeholder="RoleID">
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >

                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item style="width: 47%">
            <div class="select-group">
              <label>IsDeleted</label>
              <el-select v-model="form.IsDeleted" placeholder="IsDeleted">
                <el-option label="True" value="true"></el-option>
                <el-option label="False" value="false"></el-option>
              </el-select>
            </div>


          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="addUser = false">Cancel</el-button>
    <el-button type="primary" @click="addUsers()">Add User</el-button>
  </span>
  </el-dialog>
  <el-dialog
      title="UpdateUser"
      :visible.sync="updateUser"
      width="30%"
      center>
    <div class="modal-content">
      <el-form ref="updateUsersForm" :model="form" :rules="rules">
        <el-form-item label="Name" prop="Name">
          <el-input v-model="form.Name"></el-input>
        </el-form-item>
        <el-form-item label="UserName" prop="UserName">
          <el-input v-model="form.UserName"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="Password">
          <el-input placeholder="Парол"  show-password v-model="form.Password"></el-input>
        </el-form-item>
        <el-form-item prop="RoleId">
            <div class="select-group">
              <label>Role</label>
              <el-select v-model="form.RoleId" placeholder="RoleID">
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >

                </el-option>
              </el-select>
            </div>
        </el-form-item>
        <el-form-item>
            <div class="select-group">
              <label>IsDeleted</label>
              <el-select v-model="form.IsDeleted" placeholder="IsDeleted">
                <el-option label="True" value="true"></el-option>
                <el-option label="False" value="false"></el-option>
              </el-select>
            </div>


        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="updateUser = false">Cancel</el-button>
    <el-button type="primary" @click="updateUsers">Update User</el-button>
  </span>
  </el-dialog>
  <el-dialog
      title="Upsert User Accounts"
      :visible.sync="upsertAccounts"
      width="30%"
      center>
    <div class="modal-content">
      <el-form ref="someForm" :model="accountsForm" :rules="accountsFormRules">
        <el-form-item label="User" prop="UserId" v-if="userId == 0">
          <el-select v-model="accountsForm.UserId" placeholder="Select" style="width: 100%" v-if="userId == 0">
            <el-option
                v-for="item in usersLists"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="User" v-else>
          <el-input disabled v-model="user.name" style="color: #000"></el-input>
        </el-form-item>
        <el-form-item label="Name" prop="Name">
          <el-input v-model="accountsForm.Name"></el-input>
        </el-form-item>
        <el-form-item label="Account" prop="Account">
          <el-input v-model="accountsForm.Account"></el-input>
        </el-form-item>
        <el-form-item label="BankCode" prop="BankCode">
          <el-input v-model="accountsForm.BankCode"></el-input>
        </el-form-item>
        <el-form-item label="Branch" prop="Branch">
          <el-input v-model="accountsForm.Branch"></el-input>
        </el-form-item>
        <el-form-item label="Inn" prop="Inn">
          <el-input v-model="accountsForm.Inn"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="upsertAccounts = false">Cancel</el-button>
    <el-button type="primary" @click="upsertUserAccounts()">Upsert Accounts</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
export default {
name: "user",
  data() {
    return {
      usersList:[],
      user:[],
      name:null,
      pagination:{
        page:1,
        count: 10,
        totalCount:null
      },
      paginationAccount:{
        page:1,
        count: 10,
        totalCount:null
      },
      loading:false,
      userAccountloading:false,
      addUser: false,
      updateUser:false,
      upsertAccounts:false,
      accountsForm:{
        Id:0,
        UserId:null,
        BankCode:'',
        Branch:'',
        Name:'',
        Inn:'',
        Account:''
      },
      form:{
        Id:null,
        Name:"",
        Password:"",
        UserName:"",
        RoleId:null,
        IsDeleted:false

      },
      isUserAccount:false,
      userId:0,
      rules:{
        Name:[{
            required: true,
            trigger:["blur","change"],
            message:"Поля Name должена быть заполнено"
          }],
        UserName:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля UserName должена быть заполнено"
        }],
        Password:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля Password должена быть заполнено"
        }],
        RoleId:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля RoleId должена быть заполнено"
        }],
      },
      accountsFormRules:{
        UserId:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля User должена быть заполнено"
        }],
        Name:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля Name должена быть заполнено"
        }],
        BankCode:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля BankCode должена быть заполнено"
        }],
        Branch:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля Branch должена быть заполнено"
        }],
        Inn:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля Inn должена быть заполнено"
        }],
        Account:[{
          required: true,
          trigger:["blur","change"],
          message:"Поля Account должена быть заполнено"
        }],
      },
      userAccounts:[],
      userSelect:false,
      options:[],
      searchUsers: null,
      roles:[],
      usersLists:[]
    }
  },
  methods:{
    addModal(){
      this.addUser = true;
      this.form.Id = 0;
      this.form.Name = '';
      this.form.UserName = '';
      this.form.RoleId = null;
      this.form.IsDeleted = false
    },
    setCurrent(row) {
      this.$refs.tableDate.setCurrentRow(row);
    },
    update(val){
      this.updateUser=true
      this.form.Id = val.id
      this.form.Name = val.name;
      this.form.UserName = val.userName;
      this.form.AddedDate = val.addedDate;
      this.form.RoleId = val.roleId;
      this.form.IsDeleted = val.isDeleted
    },
    handleCurrentChangeRow(val) {
      this.userSelect = true
     if (val){
       this.userId = val.id
       this.getUser(val.id)
     }
  },
    handleCurrentChange(val){
      this.pagination.page = val;
      this.getUsers();
    },
    handleSizeChange(val){
      this.pagination.count = val;
      this.getUsers()

    },
     async getUsers(){
      this.loading = true
      let params = {
        page: this.pagination.page,
        count: this.pagination.count,
        search:this.searchUsers
      }
      try {
        const res = await this.$http.get('Admin/getUsers', {params: params});
        this.usersList = res.result.users;
        this.pagination.totalCount = res.result.count;
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      } finally {
        this.loading= false
      }
    },
    async getUserss(){
      try {
        const res = await this.$http.get('Admin/getUsers');
        this.usersLists = res.result.users;
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      }
    },
    async getUsersAccounts(){
      this.userAccountloading = true
      try {
        const res = await this.$http.get('Admin/getUserAccounts',
            {
              params: {
                userId: this.user.id
              }
            }
            );
        this.userAccounts = res.result.UserAccounts;
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      } finally {
        this.userAccountloading = false
      }
    },
    async getUser(id){
      try {
        this.isUserAccount = true;
        const res = await this.$http.get('Admin/getUser',
            {
              params: {
                id: id
              }
            }
        );
        this.user = res.result.user;
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      }
      finally
      {
        await this.getUsersAccounts()
      }

    },
    activeRow({row}){
      if (row.id == this.user.id){
        return 'current-row'
      }
      else
        return
    },
    getPageItemNumber(index){
      return ((this.pagination.page-1) * this.pagination.count) + index + 1;
    },
    showForm(form = null){
      this.accountsForm = this.createObjectForm(form);
      this.upsertAccounts = true
    },
    createObjectForm(data = null){
      if (data){
        return {
          Id:data.id,
          UserId:this.userId,
          BankCode: data.bankCode,
          Branch:data.branch,
          Name:data.name,
          Inn:data.inn,
          Account:data.account
        }
      }
      else {
        return {
          Id: 0,
          UserId:this.userId ? this.userId : null,
          BankCode:'',
          Branch:'',
          Name:'',
          Inn:'',
          Account:''
        }
      }

    },
   userAccountSearch(query){
      if (query){
        setTimeout(() => {
          this.options = this.usersList.filter(item => {
            return item.name.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      }
      else {
        this.options = []
      }

   },
    upsertUserAccounts(){
      this.$refs.someForm.validate((valid) =>{
        if (valid){
          this.showLoader();
          this.$http.post('Admin/upsertUserAccounts',this.accountsForm)
              .then(response =>{
                    console.log(response)
                    this.getUsersAccounts();
                    this.$notify({
                      title: 'Success',
                      message: 'This is a success message',
                      type: 'success'
                    });
                  },
                  error => {
                    this.$notify.error({
                      title: 'Error',
                      message: error.response.data.error.message
                    });
                  }
              )
              .finally(()=>{
                this.upsertAccounts = false;
                this.hideLoader()

              })
        }
        else return false
      })
    },
    getRoles(){
      this.$http.get('Admin/GetUserRoles')
      .then(response =>{
        this.roles = response.result
      })
    },
    getRoleName(roleId) {
      let item = this.roles.find(i => i.id == roleId);
      return item ? item.name : roleId
    },
    addUsers(){
      this.$refs.addUsersForm.validate((valid) =>{
        if (valid){
          this.showLoader();
          this.$http.post('Admin/addUser',this.form)
              .then(response =>{
                    console.log(response)
                    this.getUsers();
                    this.$notify({
                      title: 'Success',
                      message: 'This is a success message',
                      type: 'success'
                    });
                  },
                  error => {
                    this.$notify.error({
                      title: 'Error',
                      message: error.response.data.error.message
                    });
                  }
              )
              .finally(()=>{
                this.addUser = false;
                this.hideLoader()

              })
        }
        else return false
      })
    },
    updateUsers(){
      this.$refs.updateUsersForm.validate((valid) =>{
        if (valid){
          this.showLoader();
          this.$http.post('Admin/updateUser',this.form)
              .then(response =>{
                    console.log(response)
                    this.getUsers();
                    this.$notify({
                      title: 'Success',
                      message: 'This is a success message',
                      type: 'success'
                    });
                  },
                  error => {
                    this.$notify.error({
                      title: 'Error',
                      message: error.response.data.error.message
                    });
                  }
              )
              .finally(()=>{
                this.updateUser = false;
                this.hideLoader()

              })
        }
        else return false
      })
    }

  },
  created() {
    this.getUsers();
    this.getRoles();
    this.getUserss();
  },
  watch:{
  'searchUsers'(val){
    if (val.length > 2){
      this.pagination.page = null;
      this.pagination.count = null;
      this.getUsers()
    }
   else if(val.length == 0){
      this.pagination.page = 1;
      this.pagination.count = 10;
      this.getUsers()
    }
  }
  }
}
</script>

<style scoped>
.el-table__row.active__row{
  background-color: #ECF5FF !important;
}
.inline-input{
  width: 100%;
}
.el-form-item{
  margin-bottom: 10px;
}
.el-input.is-disabled .el-input__inner{
  color: #000000 !important;
}
</style>
